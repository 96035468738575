import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { DetailTargetModalProps } from './types';
import DisplayError from 'components/misc/CellDisplayError';
import { useLocalization } from 'hooks/context/useLocalization';
import { DateBadge } from 'components/ethercity-primereact';
import { Carousel } from 'primereact/carousel';

import { Divider } from 'primereact/divider';
import _, { capitalize } from 'lodash';
import LoadingMessage from 'components/misc/LoadingMessage';
import { getESTICurrentStatus } from 'utils/target';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getDomainsFromTargetEnrich } from '../TargetsDataTable/utils';
import FilterBox from 'components/datatable/FilterBox';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CMPaginator from 'components/datatable/CMPaginator';
import { getFilterData } from 'utils/datatable';
import { getMatchModeRegex } from 'services/ether/utils';
import { useQuery } from '@tanstack/react-query';
import { getOneESTI } from 'services/ether/case-manager/esti';
import SideVerticalMenu from 'components/page/SideVerticalMenu';
import { useProject } from 'hooks/context/project/useProject';
import ObjectDisplay from 'components/ethercity-primereact/components/utils/ObjectDisplay';

import './style.css';
// import useListManyEvidences from 'hooks/queries/evidence/useListManyEvidences';
import { getFileModelName } from 'utils/file';
import FileViewer from 'components/models/Evidences/FileViewer';
import useDetailOneEvidence from 'hooks/queries/evidence/useDetailOneEvidence';
import useDetailManyEvidences from 'hooks/queries/evidence/useDetailManyEvidences';
import useDownloadEvidencesFromTarget from 'hooks/queries/evidence/useDownloadEvidencesFromTarget';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { useAuth } from 'hooks/context/useAuth';
import useDownloadModelFile from 'hooks/mutations/file/useDownloadModelFile';
import useGetTargetHistory from 'hooks/queries/target/useGetTargetHistory';
import { GetTargetHistoryEP } from 'services/ether/case-manager/targets/types';

const EvidenceCarousel: React.FC<{
    evidences: Ether.CaseManager.Evidence[];
    selectedEvidenceId: string | null;
    setSelectedEvidenceId: Dispatch<SetStateAction<string | null>>;
}> = ({ selectedEvidenceId, setSelectedEvidenceId, evidences }) => {
    const [localization] = useLocalization();
    const project = useProject();

    const responsiveOptions = [
        {
            breakpoint: '1399px',
            numVisible: 4,
            numScroll: 4,
        },
        {
            breakpoint: '1199px',
            numVisible: 3,
            numScroll: 3,
        },
        {
            breakpoint: '899px',
            numVisible: 2,
            numScroll: 2,
        },
        {
            breakpoint: '699px',
            numVisible: 1,
            numScroll: 1,
        },
    ];

    const { data: evidence, isLoading } = useDetailOneEvidence(
        {
            project_id: project._id,
            _id: selectedEvidenceId ?? '',
        },
        {
            enabled: !!selectedEvidenceId,
        }
    );

    const evidenceTemplate = (
        evidence: Ether.CaseManager.Evidence.Detailed
    ) => {
        const selected = selectedEvidenceId === evidence._id;
        const isImage = evidence?.type?.startsWith('image/');
        const isPdf = evidence.extension?.includes('.pdf');
        return (
            <div
                className={[
                    'flex',
                    'flex-col',
                    'justify-between',
                    'border-2',
                    'border-solid',
                    'text-center',
                    'py-4',
                    'w-32',
                    'h-32',
                    'm-auto',
                    'hover:bg-gray-blue-700',
                    'hover:cursor-pointer',
                ].join(' ')}
                onClick={() => setSelectedEvidenceId(evidence._id)}
                style={{
                    borderColor: selected ? 'white' : 'transparent',
                }}
            >
                <i
                    className={`pi pi-${
                        isImage ? 'image' : isPdf ? 'file-pdf' : 'file'
                    }`}
                    style={{ fontSize: '4.5rem' }}
                />
                <span className='text-xs'>
                    {_(getFileModelName(evidence)).truncate({ length: 18 })}
                </span>
            </div>
        );
    };

    return (
        <div className='flex flex-col grow gap-2'>
            {isLoading ? (
                <div className='flex flex-col grow basis-0'>
                    <LoadingMessage>
                        {localization.common.loading}
                    </LoadingMessage>
                </div>
            ) : evidence ? (
                <FileViewer
                    item={evidence}
                    mode='evidence'
                    hideDownloadButton
                    previewWrapperStyles={{
                        overflowY: 'hidden',
                        flexGrow: 1,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                    imageStyles={{
                        margin: 'auto',
                    }}
                />
            ) : (
                <div className='flex flex-col grow basis-0'>
                    {localization.common.error}
                </div>
            )}

            <Carousel
                className='mt-4 h-[180px]'
                value={evidences}
                numVisible={4}
                numScroll={4}
                itemTemplate={evidenceTemplate}
                responsiveOptions={responsiveOptions}
            />
        </div>
    );
};

const TargetDetailEvidences: React.FC<{
    target: Ether.CaseManager.Target;
    refAuthorization: Ether.CaseManager.Authorization | null;
    refBlockOrder: Ether.CaseManager.BlockOrder.Detailed | null;
    refUnblockOrder: Ether.CaseManager.UnblockOrder.Detailed | null;
}> = ({ target, refAuthorization, refBlockOrder, refUnblockOrder }) => {
    const project = useProject();
    const [localization] = useLocalization();
    const { permissions } = useAuth();

    const filters = {
        project_id: project._id,
        target_id: target._id,
        authorization_id: refAuthorization
            ? refAuthorization._id
            : refBlockOrder
            ? refBlockOrder.authorizations_data?.map((a) => a._id)
            : undefined,
    };

    const { data: evidencesData, isLoading } = useDetailManyEvidences({
        project_id: project._id,
        options: {
            devFilters: filters,
        },
    });
    const evidences = evidencesData?.payload;

    const [selectedEvidenceId, setSelectedEvidenceId] = useState<string | null>(
        null
    );

    const targetEvidence = evidences?.find((e) => e._id === selectedEvidenceId);

    useEffect(() => {
        setSelectedEvidenceId(evidences?.[0]?._id ?? null);
    }, [evidences]);

    const {
        downloadFile,
        isDownloading: isDownloadingAllEvidence,
        progress,
    } = useDownloadEvidencesFromTarget();
    const { downloadEvidence, isDownloading: isDownloadingEvidence } =
        useDownloadModelFile({
            mode: 'evidence',
        });

    return (
        <div className='max-w-full flex flex-col grow'>
            <div className='flex flex-row gap-1 items-center'>
                <h2>
                    {
                        localization.components.models.target.views
                            .enrichmentDataDisplay.tabEvidences.header
                    }
                </h2>
                {targetEvidence && (
                    <Button
                        loading={isDownloadingEvidence}
                        disabled={isDownloadingEvidence}
                        label={
                            localization.components.models.evidence.button
                                .downloadCurrent
                        }
                        icon='pi pi-download'
                        onClick={() => downloadEvidence(targetEvidence)}
                    />
                )}
                {permissions.downloadEvidencesAuthorizationTarget && (
                    <>
                        <Button
                            loading={isDownloadingAllEvidence}
                            disabled={isDownloadingAllEvidence}
                            label={
                                localization.components.models.evidence.button
                                    .downloadAll
                            }
                            icon='pi pi-folder'
                            onClick={() => downloadFile(filters)}
                        />
                        {isDownloadingAllEvidence && (
                            <ProgressBar
                                className='grow'
                                value={progress * 100}
                            />
                        )}
                    </>
                )}
            </div>

            {isLoading || !evidences ? (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            ) : evidences.length <= 0 ? (
                <h3>
                    {
                        localization.components.models.target.views
                            .enrichmentDataDisplay.tabEvidences.noEvidences
                    }
                </h3>
            ) : (
                <EvidenceCarousel
                    selectedEvidenceId={selectedEvidenceId}
                    setSelectedEvidenceId={setSelectedEvidenceId}
                    evidences={evidences}
                />
            )}
        </div>
    );
};

const LabelValuePair: React.FC<{
    label: string;
    value: string | JSX.Element;
    className?: string;
}> = ({ label, value, className }) => {
    const finalClass = ['flex flex-row gap-2 items-center', className].join(
        ' '
    );
    return (
        <div className={finalClass}>
            <span className='font-bold'>{label}: </span>
            <span>{value}</span>
        </div>
    );
};

const TargetHistoryEntries: React.FC<{
    data: GetTargetHistoryEP.HistoryData[];
    route: 'authorizations' | 'block-orders' | 'unblock-orders';
}> = ({ data, route }) => {
    // TODO: LOCALIZE
    return data.map((entry) => (
        <section
            key={entry._id}
            className='ml-8 mr-4 p-4 border border-gray-blue-300 border-solid rounded-md'
        >
            <div className='flex flex-row gap-2 items-center mb-4'>
                <b>{entry.name}</b>
                {
                    <a
                        href={'/' + route + '/' + entry._id}
                        rel='noreferrer noopener'
                        target='_blank'
                    >
                        <Button icon='pi pi-external-link' size='small' />
                    </a>
                }
            </div>
            <div className='grid grid-cols-4 gap-2'>
                <LabelValuePair
                    label='Criado em'
                    value={<DateBadge value={entry.created_at} />}
                />
                <LabelValuePair
                    label='Criado por'
                    value={entry.responsible}
                    className='col-span-3'
                />
                <LabelValuePair
                    label='Enviado em'
                    value={
                        entry.registered_at ? (
                            <DateBadge value={entry.registered_at} />
                        ) : (
                            '-'
                        )
                    }
                />
                {entry.authorization_flow_data && (
                    <LabelValuePair
                        label='Fluxo'
                        className='col-span-3'
                        value={entry.authorization_flow_data.name}
                    />
                )}
                {entry.authorization_config_data && (
                    <LabelValuePair
                        className='col-span-4'
                        label='Processo'
                        value={entry.authorization_config_data.name}
                    />
                )}
                {entry.authorization_configs_data && (
                    <LabelValuePair
                        className='col-span-4'
                        label='Processos'
                        value={entry.authorization_configs_data
                            .map((c) => c.name)
                            .join(', ')}
                    />
                )}
            </div>
        </section>
    ));
};

const DetailTargetHistory: React.FC<{
    target: Ether.CaseManager.Target.Detailed;
}> = ({ target }) => {
    const [localization] = useLocalization();
    // const project = useProject();

    // const title = `${project.name} / ${localization.models.target.plural}`;

    const { data, isLoading } = useGetTargetHistory(target._id);

    const history =
        data &&
        Object.entries(data).map(([key, value]) => {
            if (value.length <= 0) return null;
            switch (key) {
                case 'authorizations':
                    return (
                        <div
                            key='authorizations'
                            className='flex flex-col gap-2'
                        >
                            <h2>
                                {
                                    localization.components.models.target.views
                                        .enrichmentDataDisplay.tabHistory.models
                                        .approvedAuthorizations
                                }
                            </h2>
                            <div className='flex flex-col gap-10'>
                                <TargetHistoryEntries
                                    data={value}
                                    route='authorizations'
                                />
                            </div>
                        </div>
                    );
                case 'removed_authorizations':
                    return (
                        <div
                            key='removed_authorizations'
                            className='flex flex-col gap-2'
                        >
                            <h2>
                                {
                                    localization.components.models.target.views
                                        .enrichmentDataDisplay.tabHistory.models
                                        .rejectedAuthorizations
                                }
                            </h2>
                            <div className='flex flex-col gap-10'>
                                <TargetHistoryEntries
                                    data={value}
                                    route='authorizations'
                                />
                            </div>
                        </div>
                    );
                case 'block_orders':
                    return (
                        <div key='block_orders' className='flex flex-col gap-4'>
                            <h2>{localization.models.blockOrder.plural}</h2>
                            <TargetHistoryEntries
                                data={value}
                                route='block-orders'
                            />
                        </div>
                    );
                case 'unblock_orders':
                    return (
                        <div
                            key='unblock_orders'
                            className='flex flex-col gap-10'
                        >
                            <h2>{localization.models.unblockOrder.plural}</h2>
                            <TargetHistoryEntries
                                data={value}
                                route='unblock-orders'
                            />
                        </div>
                    );
                default:
                    return null;
            }
        });

    return (
        <div className='flex flex-col gap-4 grow-0'>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.tabHistory.header
                }
            </h2>
            {isLoading && (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            )}
            {!isLoading && (!data || !history || history.length <= 0) && (
                <h3>{localization.common.unavailable}</h3>
            )}
            {/* <span className='text-gray-blue-300'>{title}</span> */}
            {history}
        </div>
    );
};

const TargetDetailSummary: React.FC<{
    target: Ether.CaseManager.Target.Detailed;
}> = ({ target }) => {
    const [localization] = useLocalization();
    // const project = useProject();

    // const title = `${project.name} / ${localization.models.target.plural}`;

    const status = getESTICurrentStatus({ target });

    return (
        <div className='flex flex-col gap-1 grow-0'>
            {/* <span className='text-gray-blue-300'>{title}</span> */}
            {status !== 'done' && (
                <strong className='text-yellow-500'>
                    {
                        localization.components.models.target.views
                            .enrichmentDataDisplay.pendingEstiAlert
                    }
                </strong>
            )}
        </div>
    );
};

const GroupDataVertical: React.FC<{
    className?: string;
    aboveText: string;
    belowText: string;
}> = ({ className, aboveText, belowText }) => {
    return (
        <div className={'flex flex-col' + (className ? ` ${className}` : '')}>
            <strong>{aboveText}</strong>
            <pre className='text-sm mt-[-2px]'>{belowText}</pre>
        </div>
    );
};

const DetailTargetEnrichment: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();
    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.tabEnrichment.header
                }
                <ObjectDisplay
                    displayData={esti.parsed_data}
                    sortKeys={false}
                />
            </h2>
        </section>
    );
};

const DetailTargetDomainHosting: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const { hosting_history } = esti.parsed_data ?? {};
    if (hosting_history?.status !== 'done') return <DisplayError />;

    const hostingHistoryData: { [key: string]: [string, string][] } = {};

    hostingHistoryData['ipv4'] = hosting_history.data.ipv4.map((e) => [
        e.ip,
        e.updatedate,
    ]);
    hostingHistoryData['ipv6'] = hosting_history.data.ipv6.map((e) => [
        e.ip,
        e.updatedate,
    ]);
    hostingHistoryData['dns'] = hosting_history.data.dns.map((e) => [
        e.dns,
        e.updatedate,
    ]);

    const entries = Object.entries(hostingHistoryData);

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.tabHosting.header
                }
            </h2>
            <div className='flex flex-col gap-8'>
                {entries.map(([title, obj], index) => (
                    <div key={title + index}>
                        <h3>{title.toLocaleUpperCase()}</h3>
                        <Divider color='white' />
                        {obj.map(([i, j]) => (
                            <GroupDataVertical
                                aboveText={i}
                                belowText={`Created: ${j}`}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </section>
    );
};

const DetailTargetDomainWhois: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const whois = esti.parsed_data?.whois;

    if (whois?.status !== 'done') return <DisplayError />;

    const whoIsData = Object.entries(whois.data)
        .map(([key, value]) => {
            const title = key.replaceAll('_', ' ');
            if (!value) return [title, null];
            let data: string;
            if (Array.isArray(value)) {
                data = value.join('\n');
            } else if (value instanceof Date) {
                data = value.toISOString();
            } else if (typeof value === 'object') {
                data = Object.entries(value)
                    .map(([k, v]) =>
                        v ? `${key.toLocaleUpperCase()}: ${v}` : null
                    )
                    .filter((v) => !!v)
                    .join('\n');
            } else {
                data = value;
            }
            return [title, data];
        })
        .filter(([_, data]) => !!data && data !== '') as [string, string][];

    if (Object.entries(whoIsData).every((a) => !a)) return <DisplayError />;

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.tabWhois.header
                }
            </h2>
            <div className='flex flex-col gap-4'>
                {whoIsData.map(([i, j], index) => (
                    <GroupDataVertical
                        key={index + i + j}
                        aboveText={capitalize(i)}
                        belowText={j}
                    />
                ))}
            </div>
        </section>
    );
};

const DetailTargetDomainDNS: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();
    const tabLocale =
        localization.components.models.target.views.enrichmentDataDisplay
            .tabDNS;

    const dig = esti.parsed_data?.dig;

    if (dig?.status !== 'done') return <DisplayError />;

    const digData = dig.data;

    const displayData: [string, string[]][] = [
        [tabLocale.registers.ns, digData.ns],
        [tabLocale.registers.aaaa, [...digData.a, ...digData.aaaa]],
        [tabLocale.registers.mx, digData.mx],
        [tabLocale.registers.soa, digData.soa],
        [tabLocale.registers.txt, digData.txt],
    ];

    // const displayData = [

    //     ...[...digData.ns.map((target) => ['Registros DNS', target])],
    //     ...[...digData.a, ...digData.aaaa].map((target) => ['A/AAAA', target]),
    //     ...[...digData.soa.map((target) => ['SOA', target])],
    //     ...[...digData.txt.map((target) => ['TXT', target])],
    // ];

    // const displayData = [
    //     ...[...digData.a.map((target) => ({ type: 'A', value: target }))],
    //     ...[...digData.aaaa.map((target) => ({ type: 'AAAA', value: target }))],
    //     ...[...digData.ns.map((target) => ({ type: 'NS', value: target }))],
    //     ...[...digData.soa.map((target) => ({ type: 'SOA', value: target }))],
    //     ...[...digData.txt.map((target) => ({ type: 'TXT', value: target }))],
    // ];

    return (
        <section>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.tabDomains.header
                }
            </h2>
            <div className='flex flex-col gap-8'>
                {displayData.map(([title, entries]) => {
                    if (entries.length <= 0) return null;
                    return (
                        <div key={title}>
                            <h3>{title}</h3>
                            <Divider color='white' />
                            <div className='flex flex-col gap-2'>
                                {entries.map((e, i) => (
                                    <span key={e + i}>{e}</span>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

const DetailTargetDomainGeneral: React.FC<{
    target: Ether.CaseManager.Target;
    esti: Ether.CaseManager.Esti | null;
}> = ({ target, esti }) => {
    const [localization] = useLocalization();

    const data: [string, string | JSX.Element][] = [];

    data.push([
        localization.fields.target.created,
        <DateBadge value={target.created_at} />,
    ]);

    if (esti?.parsed_data?.whois?.status === 'done') {
        const whoisData = esti.parsed_data.whois.data;
        if (whoisData.domain_registrar?.registrar_name)
            data.push(['Registrar', whoisData.domain_registrar.registrar_name]);
        else if (whoisData.registrar)
            data.push(['Registrar', whoisData.registrar]);
    }

    if (esti?.parsed_data?.liveness?.status === 'done') {
        const liveData = esti.parsed_data.liveness.data;
        if (liveData.resolved_url)
            data.push(['Resolved URL', liveData.resolved_url]);
        if (liveData.is_active != null)
            data.push(['Currently active?', liveData.is_active ? 'Yes' : 'No']);
        if (liveData.last_check_at)
            data.push([
                'Last active check',
                liveData.last_check_at.toISOString(),
            ]);
    }

    return (
        <section>
            <div className='flex flex-row justify-start'>
                <a
                    href={'//' + target.value}
                    rel='noreferrer noopener'
                    target='_blank'
                    className='flex flex-row gap-2 items-center justify-start'
                >
                    <h2>
                        {localization.components.models.target.views
                            .enrichmentDataDisplay.tabGeneral.header +
                            ' - ' +
                            target.value}
                    </h2>
                    <i className='pi pi-external-link' />
                </a>
            </div>
            <div className='grid grid-cols-3 gap-4'>
                {data.map(([i, j]) => (
                    <LabelValuePair label={i} value={j} key={i + j} />
                ))}
            </div>
        </section>
    );
};

// const DetailTargetIPHistory = () => {
//     const [localization] = useLocalization();

//     const fakeData = [
//         ['bucanerozone.com', 'Início: 20/12/2023 - Término: 23/12/2023'],
//         ['roguestreamer.io', 'Início: 30/11/2023 - Término: 03/12/2023'],
//         ['plunderbay.org', 'Início: 05/09/2023 - Término: 08/09/2023'],
//         ['piratemingle.net', 'Início: 10/06/2023 - Término: 13/06/2023'],
//         ['bootlegflix.co', 'Início: 15/03/2023 - Término: 18/03/2023'],
//         ['counterfeitvault.com', 'Início: 10/11/2022 - Término: 13/11/2022'],
//         ['swashbucklehub.net', 'Início: 15/08/2022 - Término: 18/08/2022'],
//         ['privateerstreams.org', 'Início: 20/05/2022 - Término: 23/05/2022'],
//         ['freebooterflix.co', 'Início: 25/02/2022 - Término: 28/02/2022'],
//         ['piratetreasurebay.io', 'Início: 05/02/2022 - Término: 08/02/2022'],
//     ];

//     return (
//         <section>
//             <h2>{localization.components.models.target.views.enrichmentDataDisplay.titleHistory}</h2>
//             <div className='flex flex-col gap-4'>
//                 {fakeData.map(([i, j]) => (
//                     <GroupDataVertical
//                         aboveText={i as string}
//                         belowText={j as string}
//                     />
//                 ))}
//             </div>
//         </section>
//     );
// };

const DetailTargetIPDetails: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const finalInfo: {
        asnupstreams:
            | null
            | {
                  code: number;
                  name: string;
              }[];
        subnethost:
            | null
            | {
                  ip: string;
                  count: number;
                  example: string;
              }[];
        blocking: null | {
            dnsbl: boolean | undefined;
            adulthosting: boolean | undefined;
            mthreats: boolean | undefined;
            openproxy: boolean | undefined;
        };
    } = {
        subnethost: null,
        asnupstreams: null,
        blocking: null,
    };

    if (esti.parsed_data?.upstreams?.status === 'done') {
        finalInfo.asnupstreams =
            esti.parsed_data.upstreams.data.map((a) => ({
                code: a.asn_code,
                name: a.asn_name,
            })) ?? null;
    }
    if (esti.parsed_data?.overview?.status === 'done') {
        finalInfo.subnethost =
            esti.parsed_data.overview.data.subnethosting?.ips?.map((a) => ({
                count: a.count,
                example: a.example,
                ip: a.ip,
            })) ?? null;
    }
    if (esti.parsed_data?.block?.status === 'done') {
        const data = esti.parsed_data.block.data;
        finalInfo.blocking = {
            adulthosting: data.adulthosting,
            dnsbl: data.dnsbl,
            mthreats: data.mthreats,
            openproxy: data.openproxy,
        };
        if (Object.entries(finalInfo.blocking).every((t) => t == null))
            finalInfo.blocking = null;
    }

    if (Object.values(finalInfo).every((a) => !a)) return <DisplayError />;

    return (
        <section className='max-h-full'>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.tabDetails.header
                }
            </h2>
            <div>
                {finalInfo.asnupstreams && (
                    <>
                        <h3>ASN Upstreams</h3>
                        <div className='flex flex-col gap-1'>
                            {finalInfo.asnupstreams.map((e) => (
                                <span>
                                    <strong>{e.name}</strong> - {e.code}
                                </span>
                            ))}
                        </div>
                    </>
                )}
                {finalInfo.subnethost && (
                    <>
                        <h3>Subnet hosts</h3>
                        <div className='flex flex-col gap-4'>
                            {finalInfo.subnethost.map((e) => (
                                <span>
                                    <strong>{e.ip}</strong> - Found on {e.count}{' '}
                                    domains (e.g. {e.example}){' '}
                                </span>
                            ))}
                        </div>
                    </>
                )}
                {finalInfo.blocking && (
                    <>
                        <h3>Blocking</h3>
                        <div className='flex flex-col'>
                            <span>
                                Is DNS blocked:{' '}
                                <strong>
                                    {finalInfo.blocking.dnsbl != null
                                        ? finalInfo.blocking.dnsbl
                                            ? 'Yes'
                                            : 'No'
                                        : 'Unknown'}
                                </strong>
                            </span>
                            <span>
                                Is adult host:{' '}
                                <strong>
                                    {finalInfo.blocking.adulthosting != null
                                        ? finalInfo.blocking.adulthosting
                                            ? 'Yes'
                                            : 'No'
                                        : 'Unknown'}
                                </strong>
                            </span>
                            <span>
                                Is malicious DNS:{' '}
                                <strong>
                                    {finalInfo.blocking.mthreats != null
                                        ? finalInfo.blocking.mthreats
                                            ? 'Yes'
                                            : 'No'
                                        : 'Unknown'}
                                </strong>
                            </span>
                            <span>
                                Is open proxy:{' '}
                                <strong>
                                    {finalInfo.blocking.openproxy != null
                                        ? finalInfo.blocking.openproxy
                                            ? 'Yes'
                                            : 'No'
                                        : 'Unknown'}
                                </strong>
                            </span>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

const DetailTargetIPDomains: React.FC<{
    esti: Ether.CaseManager.Esti;
}> = ({ esti }) => {
    const [localization] = useLocalization();

    const domainsList = getDomainsFromTargetEnrich(esti);

    const { filters, setFilters, pageOptions, setPageOptions } =
        useInitDataTableState({
            filters: {
                _cm_name_id: getFilterData(),
            },
        });

    // const data = [
    //     ...[...domainsListData.map((target) => ['Domain', target])],
    //     ...[...(overviewData.mxrecords ?? []).map((target) => ['MX', target])],
    //     ...[...(overviewData.nsrecords ?? []).map((target) => ['NS', target])],
    // ];
    if (!domainsList || domainsList.length <= 0) return <DisplayError />;

    const data = domainsList.map((target) => ({
        type: 'Domain',
        value: target,
    }));

    const filteredData = data.filter(({ value: domain }) => {
        if (!filters['_cm_name_id']) return true;
        const filterData = filters['_cm_name_id'];
        const option =
            'value' in filterData ? filterData : filterData.constraints[0];
        if (!option) return true;
        const { value, matchMode } = option;
        if (!value) return true;
        switch (matchMode) {
            case 'startsWith':
                return !!domain.match(getMatchModeRegex(value, matchMode));
            case 'contains':
                return !!domain.match(getMatchModeRegex(value, matchMode));
            case 'equals':
                return value.toLocaleLowerCase() === domain.toLocaleLowerCase();
            case 'endsWith':
                return !!domain.match(getMatchModeRegex(value, matchMode));
            case 'notContains':
                return !!domain.match(getMatchModeRegex(value, matchMode));
            case 'notEquals':
                return value.toLocaleLowerCase() !== domain.toLocaleLowerCase();
            default:
                return true;
        }
    });

    const startIndex = (pageOptions.page - 1) * pageOptions.rows;
    const endIndex = startIndex + pageOptions.rows;
    const trimmedPageData = filteredData.slice(startIndex, endIndex);

    return (
        <section className='max-h-full'>
            <h2>
                {
                    localization.components.models.target.views
                        .enrichmentDataDisplay.tabDomains.header
                }
            </h2>
            <span>
                {localization.components.common.datatable.totalAmount.replace(
                    '{count}',
                    filteredData.length.toString()
                )}
            </span>
            <FilterBox
                filters={filters}
                setFilters={setFilters}
                filterOptions={{
                    _cm_name_id: {
                        type: 'string',
                    },
                }}
                hideBorder
            />
            <CMPaginator
                page={pageOptions.page}
                rows={pageOptions.rows}
                onPageChange={(e) => setPageOptions(e)}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                maxPages={Math.ceil(filteredData.length / pageOptions.rows)}
            />
            {localization.components.common.datatable.showingAmount.replace(
                '{count}',
                trimmedPageData.length.toString()
            )}
            <DataTable
                value={trimmedPageData}
                showHeaders={false}
                className='max-h-full mt-2'
            >
                <Column field='value' />
            </DataTable>
        </section>
    );
};

const DetailTargetIPGeneral: React.FC<{
    target: Ether.CaseManager.Target;
    esti: Ether.CaseManager.Esti | null;
}> = ({ target, esti }) => {
    const [localization] = useLocalization();

    const info = esti?.parsed_data;

    const data: [string, string | JSX.Element][] = [];

    data.push([
        localization.fields.target.created,
        <DateBadge value={target.created_at} />,
    ]);

    if (info?.asn?.status === 'done') {
        const asnData = info.asn.data;
        if (asnData.code || asnData.name) {
            const { code, name } = asnData;
            let str =
                code && name
                    ? `${name} (${code})`
                    : name
                    ? name
                    : code?.toString();
            if (str) data.push(['ASN', str]);
        }
    }

    if (info?.geo?.status === 'done') {
        const geoData = info.geo.data;
        if (geoData.continent?.code || geoData.continent?.name) {
            const { code, name } = geoData.continent;
            let str = code && name ? `${name} (${code})` : name ? name : code;
            if (str) data.push(['Continent', str]);
        }
        if (geoData.country?.code || geoData.country?.name) {
            const { code, name } = geoData.country;
            let str = code && name ? `${name} (${code})` : name ? name : code;
            if (str) data.push(['Country', str]);
        }
        if (geoData.state?.code || geoData.state?.name) {
            const { code, name } = geoData.state;
            let str = code && name ? `${name} (${code})` : name ? name : code;
            if (str) data.push(['State', str]);
        }
        if (geoData.city?.code || geoData.city?.name) {
            const { code, name } = geoData.city;
            let str = code && name ? `${name} (${code})` : name ? name : code;
            if (str) data.push(['City', str]);
        }
        if (geoData.location?.latitude && geoData.location?.latitude)
            data.push([
                'Location',
                `Latitude: ${geoData.location.latitude}, Longitutde: ${geoData.location.longitude}`,
            ]);
    }

    if (info?.open_ports?.status === 'done') {
        const openPortsData = info.open_ports.data;
        if (openPortsData.ports)
            data.push(['Open ports', openPortsData.ports.join(', ')]);
    }

    if (data.length <= 0) return <DisplayError />;

    return (
        <section>
            <div className='flex flex-row justify-start'>
                <a
                    href={'//' + target.value}
                    rel='noreferrer noopener'
                    target='_blank'
                    className='flex flex-row gap-2 items-center justify-start'
                >
                    <h2>
                        {localization.components.models.target.views
                            .enrichmentDataDisplay.tabGeneral.header +
                            ' - ' +
                            target.value}
                    </h2>
                    <i className='pi pi-external-link' />
                </a>
            </div>
            <div className='grid grid-cols-3 gap-4'>
                {data.map(([i, j]) => (
                    <LabelValuePair key={i + j} label={i} value={j} />
                ))}
            </div>
        </section>
    );
};

const DetailTargetMenu: React.FC<{
    type: 'ip' | 'domain';
    target: Ether.CaseManager.Target.Detailed;
    refAuthorization: Ether.CaseManager.Authorization | null;
    refBlockOrder: Ether.CaseManager.BlockOrder.Detailed | null;
    refUnblockOrder: Ether.CaseManager.UnblockOrder.Detailed | null;
    esti: Ether.CaseManager.Esti | null;
}> = ({
    type,
    target,
    esti,
    refAuthorization,
    refBlockOrder,
    refUnblockOrder,
}) => {
    const [localization] = useLocalization();
    const [activeId, setActiveId] = useState('general');

    const thisLocalization =
        localization.components.models.target.views.enrichmentDataDisplay;

    const tabOptionsDomain = [
        {
            id: 'general',
            icon: 'pi pi-home',
            label: thisLocalization.tabGeneral.title,
            element: () => (
                <DetailTargetDomainGeneral target={target} esti={esti} />
            ),
        },
        {
            id: 'history',
            icon: 'pi pi-info-circle',
            label: thisLocalization.tabHistory.title,
            element: () => <DetailTargetHistory target={target} />,
        },
        {
            id: 'dns',
            icon: 'pi pi-database',
            label: thisLocalization.tabDNS.title,
            disabled: !esti,
            tooltip: !esti ? localization.common.unavailable : undefined,
            element: () => esti && <DetailTargetDomainDNS esti={esti} />,
        },
        {
            id: 'whois',
            icon: 'pi pi-globe',
            label: thisLocalization.tabWhois.title,
            disabled: !esti,
            tooltip: !esti ? localization.common.unavailable : undefined,
            element: () => esti && <DetailTargetDomainWhois esti={esti} />,
        },
        {
            id: 'hosting',
            icon: 'pi pi-book',
            label: thisLocalization.tabHosting.title,
            disabled: !esti,
            tooltip: !esti ? localization.common.unavailable : undefined,
            element: () => esti && <DetailTargetDomainHosting esti={esti} />,
        },
        {
            id: 'enrichment',
            icon: 'pi pi-search',
            label: thisLocalization.tabEnrichment.title,
            disabled: !esti,
            tooltip: !esti ? localization.common.unavailable : undefined,
            element: () => esti && <DetailTargetEnrichment esti={esti} />,
        },
        {
            id: 'evidences',
            icon: 'pi pi-file',
            label: thisLocalization.tabEvidences.title,
            element: () => (
                <TargetDetailEvidences
                    target={target}
                    refAuthorization={refAuthorization}
                    refBlockOrder={refBlockOrder}
                    refUnblockOrder={refUnblockOrder}
                />
            ),
        },
    ];

    const tabOptionsIP = [
        {
            id: 'general',
            icon: 'pi pi-home',
            label: thisLocalization.tabGeneral.title,
            element: () => (
                <DetailTargetIPGeneral target={target} esti={esti} />
            ),
        },
        {
            id: 'history',
            icon: 'pi pi-info-circle',
            label: thisLocalization.tabHistory.title,
            element: () => <DetailTargetHistory target={target} />,
        },
        {
            id: 'domains',
            icon: 'pi pi-database',
            label: thisLocalization.tabDomains.title,
            disabled: !esti,
            tooltip: !esti ? localization.common.unavailable : undefined,
            element: () => esti && <DetailTargetIPDomains esti={esti} />,
        },
        {
            id: 'details',
            icon: 'pi pi-star',
            label: thisLocalization.tabDetails.title,
            disabled: !esti,
            tooltip: !esti ? localization.common.unavailable : undefined,
            element: () => esti && <DetailTargetIPDetails esti={esti} />,
        },
        // {
        //     label: thisLocalization.tabHistory,
        //     element :() => <DetailTargetDomainHistory esti={esti} />,
        // },
        {
            id: 'enrichment',
            icon: 'pi pi-search',
            label: thisLocalization.tabEnrichment.title,
            disabled: !esti,
            tooltip: !esti ? localization.common.unavailable : undefined,
            element: () => esti && <DetailTargetEnrichment esti={esti} />,
        },
        {
            id: 'evidences',
            icon: 'pi pi-file',
            label: thisLocalization.tabEvidences.title,
            element: () => (
                <TargetDetailEvidences
                    target={target}
                    refAuthorization={refAuthorization}
                    refBlockOrder={refBlockOrder}
                    refUnblockOrder={refUnblockOrder}
                />
            ),
        },
    ];

    const tabOptions = type === 'ip' ? tabOptionsIP : tabOptionsDomain;

    return (
        <section className='grid grid-cols-10 gap-4 h-full'>
            <div className='col-span-2'>
                <SideVerticalMenu
                    menuItems={tabOptions}
                    activeId={activeId}
                    onChange={({ id }) => setActiveId(id)}
                />
            </div>
            <div className='col-span-8 overflow-scroll h-full flex flex-col'>
                <TargetDetailSummary target={target} />
                {tabOptions.map(
                    (t) => activeId === t.id && <t.element key={t.id} />
                )}
            </div>
        </section>
    );
};

const DetailTargetModal: React.FC<DetailTargetModalProps> = ({
    target,
    refAuthorization,
    refBlockOrder,
    refUnblockOrder,
    ...props
}) => {
    const [localization] = useLocalization();

    const estiQuery = useQuery({
        queryKey: ['target', 'esti', target?.value],
        queryFn: () =>
            getOneESTI({
                target_value: target?.value ?? '',
            }),
        enabled: !!target,
    });

    let element = (
        <LoadingMessage>{localization.common.loading}</LoadingMessage>
    );

    if (target && !estiQuery.isLoading) {
        element = (
            <DetailTargetMenu
                type={
                    target.type === 'ipv4' || target.type === 'ipv6'
                        ? 'ip'
                        : 'domain'
                }
                target={target}
                esti={estiQuery.data ?? null}
                refAuthorization={refAuthorization}
                refBlockOrder={refBlockOrder}
                refUnblockOrder={refUnblockOrder}
            />
        );
    }

    return (
        <Dialog
            header={
                <div className='flex flex-row gap-2'>
                    <span>
                        {localization.components.models.target.views
                            .enrichmentDataDisplay.title + ' - '}
                    </span>
                    <span>
                        <a
                            href={'//' + target?.value}
                            rel='noreferrer noopener'
                            target='_blank'
                            className='flex flex-row gap-2 items-center justify-start'
                        >
                            <span>{target?.value}</span>
                            <i className='pi pi-external-link' />
                        </a>
                    </span>
                </div>
            }
            {...props}
            className={[props.className, 'h-[88vh]', 'min-w-[80vw]'].join(' ')}
            contentClassName={[props.contentClassName, 'h-full', 'w-full'].join(
                ' '
            )}
        >
            {element}
        </Dialog>
    );
};

export default DetailTargetModal;
