import { filesize } from 'filesize';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CSSProperties, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocalization } from 'hooks/context/useLocalization';
import { FileDownload } from 'services/ether/base';
import useDownloadModelFile from 'hooks/mutations/file/useDownloadModelFile';

const PcapDetail: React.FC<{
    data: {
        summary: string;
        type: string;
        source: string;
        destination: string;
        source_ip: string;
        destination_ip: string;
        source_port: number;
        destination_port: number;
        connections_count: number;
        bytes_total: number;
    };
}> = ({ data }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className='flex flex-row gap-2'>
            <Button
                icon={isExpanded ? 'pi pi-minus' : 'pi pi-plus'}
                onClick={() => setIsExpanded((old) => !old)}
            />
            <div className='flex flex-col'>
                <span>{data.summary}</span>
                <span>
                    Connections count: <strong>{data.connections_count}</strong>
                </span>
                <span>
                    Total bytes: <strong>{data.bytes_total}</strong>
                </span>
                {isExpanded && (
                    <>
                        <span>Type: {data.type}</span>
                        <span>
                            Source {data.source} {'->'} Destination:{' '}
                            {data.destination}
                        </span>
                        <span>
                            Source IP {data.source_ip} {'->'} Destination IP:{' '}
                            {data.destination_ip}
                        </span>
                        <span>
                            Source Port {data.source_port} {'->'} Destination
                            Port: {data.destination_port}
                        </span>
                    </>
                )}
            </div>
        </div>
    );
};

const PcapViewer: React.FC<{
    parsedData: {
        len_total: number;
        len_unique: number;
        result: {
            summary: string;
            type: string;
            source: string;
            destination: string;
            source_ip: string;
            destination_ip: string;
            source_port: number;
            destination_port: number;
            connections_count: number;
            bytes_total: number;
        }[];
    };
}> = ({ parsedData }) => {
    const { result } = parsedData;
    return (
        <section className='max-h-[58vh] overflow-scroll w-full text-sm flex flex-col gap-4'>
            {result.map((r) => (
                <PcapDetail data={r} />
            ))}
        </section>
    );
};

export type FileViewerProps = {
    item: Ether.CaseManager.Document | Ether.CaseManager.Evidence;
    mode: 'document' | 'evidence';
    token?: string;
    showCloseButton?: boolean;
    onClose?: () => void;
    hideDownloadButton?: boolean;
    imageStyles?: CSSProperties;
    previewWrapperStyles?: CSSProperties;
};

const FileViewer: React.FC<FileViewerProps> = ({
    item,
    mode,
    token,
    showCloseButton,
    onClose,
    hideDownloadButton,
    imageStyles,
    previewWrapperStyles,
}) => {
    const [localization] = useLocalization();

    const isImage = item?.type?.startsWith('image/') ?? false;
    const isVideo = item?.type?.startsWith('video/') ?? false;
    const isPcap =
        item &&
        'type' in item &&
        (item.extension?.endsWith('pcap') ||
            item.extension?.endsWith('pcapng'));

    const isPreviewable = isImage || isVideo;
    const isParsePreviable = isPcap;

    const { downloadDocument, downloadEvidence, downloadItem, isDownloading } =
        useDownloadModelFile({ mode });

    const fileDataQuery = useQuery<FileDownload.Blob.Item | null, Error>({
        queryKey: ['model-file', mode, item._id],
        queryFn: () => downloadItem(item, token),
        enabled: isPreviewable,
        retry: 1,
    });

    let mediaUrl: string | null = null;

    if (fileDataQuery.data) {
        const url = URL.createObjectURL(fileDataQuery.data.file);
        mediaUrl = url;
    }

    const onDownloadClick = () => {
        if (mode === 'document') downloadDocument(item);
        if (mode === 'evidence') downloadEvidence(item, token);
    };

    return (
        <div className='flex flex-col grow basis-0 gap-4 items-center justify-between overflow-y-scroll'>
            <div style={previewWrapperStyles}>
                {isImage && mediaUrl && (
                    <img
                        className='max-w-full max-h-full'
                        src={mediaUrl}
                        aria-label={`File ${item?.file.name}`}
                        style={imageStyles}
                    />
                )}
                {isVideo && mediaUrl && (
                    <video className='max-w-full max-h-full' controls>
                        <source src={mediaUrl} type={item.type} />
                    </video>
                )}
                {!isPreviewable && !isParsePreviable && (
                    <div>
                        <span>
                            {
                                localization.components.views.file.preview
                                    .noPreviewIntro
                            }
                        </span>
                        <span>
                            {
                                localization.components.views.file.preview
                                    .noPreviewDescription
                            }
                        </span>
                    </div>
                )}
                {fileDataQuery.error && (
                    <div style={previewWrapperStyles}>
                        <span>
                            {
                                localization.components.views.file.preview
                                    .filePreviewFailed
                            }
                        </span>
                        {/* <span>{fileDataQuery.error.message}</span> */}
                    </div>
                )}
                {isPreviewable && fileDataQuery.isLoading && (
                    <div className='flex flex-row items-center gap-2'>
                        <ProgressSpinner
                            style={{ width: '30px', height: '30px' }}
                            strokeWidth='4'
                        />
                        <span>{localization.common.loading}...</span>
                    </div>
                )}

                {isParsePreviable && item.parsed_data == null && (
                    <div className='flex flex-col gap-1'>
                        <span>
                            {
                                localization.components.views.file.preview
                                    .fileBeingParsed
                            }
                        </span>
                        <span>
                            {
                                localization.components.views.file.preview
                                    .noPreviewDescription
                            }
                        </span>
                    </div>
                )}
                {isParsePreviable &&
                    item.parsed_data &&
                    'error' in item.parsed_data && (
                        <div
                            className='flex flex-col gap-1'
                            style={previewWrapperStyles}
                        >
                            <span>
                                {
                                    localization.components.views.file.preview
                                        .fileParseError
                                }
                            </span>
                            <span>
                                {
                                    localization.components.views.file.preview
                                        .noPreviewDescription
                                }
                            </span>
                        </div>
                    )}
                {isParsePreviable &&
                item.parsed_data &&
                !('error' in item.parsed_data) &&
                isPcap ? (
                    <div style={previewWrapperStyles}>
                        <PcapViewer parsedData={item.parsed_data} />
                    </div>
                ) : null}
            </div>
            <div className='flex flex-col justify-center grow-0 self-start'>
                <strong>{item?.file.name}</strong>
                <span>{filesize(item?.file.size ?? 0)}</span>
            </div>
            {(!hideDownloadButton || showCloseButton) && (
                <div className='flex gap-2 w-full'>
                    {!hideDownloadButton && (
                        <Button
                            icon='pi pi-download'
                            loading={isDownloading}
                            onClick={onDownloadClick}
                            label={
                                localization.components.common.button.download
                            }
                            style={{ width: '100%' }}
                        />
                    )}
                    {showCloseButton && (
                        <Button
                            icon='pi pi-times'
                            onClick={onClose}
                            label={localization.components.common.button.close}
                            style={{ width: '100%' }}
                        />
                    )}
                </div>
            )}
        </div>
    );
};

export default FileViewer;
