import { isArray } from 'lodash';
import { CMLocalization } from 'static/language';

export const truncateTargetName = (target: string) => {
    const length = target.length;
    const reticences = '...';
    const max = 60 + reticences.length;
    if (length > max)
        return target.slice(0, max - reticences.length) + reticences;
    return target;
};

export const getESTICurrentStatus = ({
    target,
}: {
    target: Ether.CaseManager.Target.Detailed;
}): 'error' | 'pending' | 'done' => {
    if (
        target.target_info_sync_status === 'pending' ||
        target.target_info_sync_status === 'synced'
    )
        return 'pending';
    if (target.target_info_sync_status === 'error') return 'error';
    return target.list_data?.esti_status ?? 'pending';
};

export const translateSafeIpReasons = (
    target: Ether.CaseManager.Target.Detailed,
    localization: CMLocalization.Localization
) => {
    const safeIpReasonBadge =
        localization.components.models.target.badge.safeIpReason;
    const safeIpLocale =
        localization.components.models.target.views.safeIpDetailReasoning;
    const reasons = target.list_data?.safe_ip_reasons;
    if (!reasons) return [];
    const messages: string[] = [];
    reasons.forEach((r) => {
        const grading =
            'grading' in r.meta && r.meta.grading ? `[${r.meta.grading}] ` : '';
        switch (r.reason) {
            case 'domain_is_old':
                messages.push(grading + safeIpReasonBadge.domain_is_old);
                break;
            case 'hosts_official_domain':
                messages.push(
                    grading +
                        (r.meta.description ??
                            safeIpReasonBadge.hosts_official_domain.replace(
                                '{domain}',
                                isArray(r.meta.match)
                                    ? r.meta.match.join('.')
                                    : r.meta.match
                            ))
                );
                break;
            case 'is_official_domain':
                const initialMessage =
                    target.type === 'domain'
                        ? safeIpReasonBadge.is_official_domain
                        : safeIpReasonBadge.hosts_official_domain;
                if ('domain' in r.meta) {
                    messages.push(
                        grading +
                            initialMessage.replace('{domain}', r.meta.domain)
                    );
                } else {
                    messages.push(
                        grading +
                            (r.meta.description ??
                                initialMessage.replace(
                                    '{domain}',
                                    isArray(r.meta.match)
                                        ? r.meta.match.join('.')
                                        : r.meta.match
                                ))
                    );
                }
                break;
            case 'is_reserved_ip':
                messages.push(grading + safeIpReasonBadge.is_reserved_ip);
                break;
            case 'is_trusted_asn_port':
                messages.push(
                    grading +
                        safeIpReasonBadge.is_trusted_asn_port
                            .replace('{asn}', r.meta.asn)
                            .replace('{port}', r.meta.ports.join(', '))
                );
                break;
            case 'is_trusted_asn_tld':
                messages.push(
                    grading +
                        safeIpReasonBadge.is_trusted_asn_tld
                            .replace('{asn}', r.meta.asn)
                            .replace('{tld}', r.meta.tld)
                );
                break;
            case 'is_trusted_geo':
                messages.push(
                    grading +
                        safeIpReasonBadge.is_trusted_geo.replace(
                            '{country}',
                            r.meta.country.code
                        )
                );
                break;
            case 'is_trusted_geo_port':
                messages.push(
                    grading +
                        safeIpReasonBadge.is_trusted_geo_port
                            .replace('{country}', r.meta.country.code)
                            .replace('{port}', r.meta.ports.join(', '))
                );
                break;
            case 'is_trusted_ip':
                if ('ip' in r.meta) {
                    const message =
                        r.meta.description ??
                        safeIpLocale.trustedTargetWithin
                            .replace('{ip}', r.meta.ip)
                            .replace('{network}', r.meta.network);
                    messages.push(grading + message);
                } else {
                    const message =
                        r.meta.description ??
                        safeIpLocale.trustedTargetWithin
                            .replace('{ip}', r.meta.value)
                            .replace('{network}', r.meta.match);
                    messages.push(grading + message);
                }
                break;
            case 'is_trusted_port':
                messages.push(
                    grading +
                        safeIpReasonBadge.is_trusted_port.replace(
                            '{ports}',
                            r.meta.ports.join(', ')
                        )
                );
                break;
            case 'is_trusted_tld':
                messages.push(
                    grading +
                        safeIpReasonBadge.is_trusted_tld.replace(
                            '{tld}',
                            r.meta.tld
                        )
                );
                break;
            case 'is_trusted_tld_geo':
                messages.push(
                    grading +
                        safeIpReasonBadge.is_trusted_tld_geo
                            .replace('{tld}', r.meta.tld)
                            .replace('{country}', r.meta.country.code)
                );
                break;
            case 'is_trusted_whois_field':
                const whoIsMessage =
                    r.meta.field in safeIpLocale.whoIsFields
                        ? (safeIpLocale.whoIsFields as any)[
                              r.meta.field
                          ].replace('{value}', r.meta.value)
                        : safeIpLocale.whoIsGeneric
                              .replace('{value}', r.meta.value)
                              .replace('{field}', r.meta.field);
                messages.push(
                    grading +
                        safeIpReasonBadge.is_trusted_whois_field.replace(
                            '{message}',
                            whoIsMessage
                        )
                );
                break;
            case 'domains_count_exceeded':
                const domainsCountMessage =
                    safeIpReasonBadge.domains_count_exceeded
                        .replace('{max_count}', r.meta.max_count.toString())
                        .replace('{count}', r.meta.count.toString());
                messages.push(grading + domainsCountMessage);
        }
    });
    return messages;
};

export const nestTagList = (
    tags: Ether.CaseManager.Subtag.Detailed[],
    parentTag: Ether.CaseManager.Subtag.Detailed | null = null
) => {
    let nestedTags: Ether.CaseManager.Target.AuthorizationTag[] = [];
    if (!parentTag) {
        const firstTag = tags[0];
        if (!firstTag) return [];
        nestedTags.push({
            _id: firstTag._id,
            category: firstTag.category,
            name: firstTag.name,
            subtags: nestTagList(tags, firstTag),
        });
    } else {
        parentTag.subtags.forEach((subtag) => {
            subtag.tags.forEach((tag) => {
                if (!tags.find((tag2) => tag._id === tag2._id)) return;
                nestedTags.push({
                    _id: tag._id,
                    category: tag.category,
                    name: tag.name,
                    subtags: nestTagList(tags, tag),
                });
            });
        });
    }

    return nestedTags;
};

export const objetifyNestedTagList = (
    tags: Ether.CaseManager.Target.AuthorizationTag[],
    prefix: string | null = null
) => {
    let tagsObj: Record<string, string[]> = {};
    tags.forEach((item) => {
        const fullCategory = prefix
            ? `${prefix}/${item.category}`
            : item.category;
        if (!(fullCategory in tagsObj)) tagsObj[fullCategory] = [];
        tagsObj[fullCategory]?.push(item.name);
        if (item.subtags && item.subtags.length > 0)
            tagsObj = {
                ...tagsObj,
                ...objetifyNestedTagList(item.subtags, fullCategory),
            };
    });
    return tagsObj;
};
