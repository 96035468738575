import { useLocalization } from 'hooks/context/useLocalization';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { DetailTagEP } from 'services/ether/case-manager/tags/types';
import SelectOneTag from '../SelectOneTag';

const SelectOneCountry: React.FC<
    SelectOneItemProps<Ether.CaseManager.Tag, DetailTagEP.Filters.Options>
> = (props) => {
    const [localization] = useLocalization();

    return (
        <SelectOneTag
            label={localization.models.tag.types.country.singular}
            title={localization.models.tag.types.country.singular}
            placeholder={localization.components.models.tag.types.country.form.selectOnePlaceholder}
            {...props}
            queryOptions={{
                ...props.queryOptions,
                devFilters: {
                    ...props.queryOptions?.devFilters,
                    category: 'country',
                    model_type : 'authorization',
                },
            }}
        />
    );
};

export default SelectOneCountry;
