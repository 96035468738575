import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { listTags } from 'services/ether/case-manager/tags';
import { ListTagEP } from 'services/ether/case-manager/tags/types';

const useListTag = (
    params: ListTagEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['tag', 'list', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) => listTags({ ...params, signal }),
        ...queryOptions,
    });

    return query;
};

export default useListTag;
