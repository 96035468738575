import { useProject } from 'hooks/context/project/useProject';
import CMBreadCrumb from 'components/page/CMBreadCrumb';
import { useLocalization } from 'hooks/context/useLocalization';
import GoBackButton from 'components/misc/GoBackButton';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import CacheControl from 'controller/cache/cacheController';
import useDetailManyOperation from 'hooks/queries/operation/useDetailManyOperation';
import OperationDataTable, {
    operationFilters,
} from 'components/models/Operation/OperationDataTable';

const ListOperations = () => {
    const project = useProject();
    const [localization] = useLocalization();

    const { filters, pageOptions, setFilters, setPageOptions, sort, setSort } =
        useInitDataTableState({
            filters: operationFilters,
            pageOptions: {
                startingRow:
                    CacheControl.UserConfig.get()['paginatorRows']?.operation,
            },
        });

    const query = useDetailManyOperation({
        project_id: project._id,
        options: {
            rawFilters: filters,
            limit: pageOptions.rows,
            offset: (pageOptions.page - 1) * pageOptions.rows,
            sort,
        },
    });

    return (
        <section>
            <CMBreadCrumb projectLabel={project.name} />
            <GoBackButton />
            <h2>
                {project.name} - {localization.models.operation.plural}
            </h2>
            <OperationDataTable
                loading={query.isLoading}
                value={query.data?.payload}
                filters={filters}
                setFilters={(e) => setFilters(e)}
                sortField={sort?.field}
                sortOrder={sort?.order}
                onSort={(e) =>
                    setSort({
                        field: e.sortField,
                        order: e.sortOrder,
                    })
                }
                paginatorProps={{
                    page: pageOptions.page,
                    rows: pageOptions.rows,
                    onPageChange: (options) => setPageOptions(options),
                    disableNext:
                        !query.data || query.data.payload.length < pageOptions.rows,
                    onRefresh: query.refresh,
                }}
            />
        </section>
    );
};

export default ListOperations;
