import { useProject } from 'hooks/context/project/useProject';
import useListTag from 'hooks/queries/tag/useListTag';
import { SortOrder } from 'primereact/api';

type AvailableTagFilters = 'pirate_brand' | 'country' | 'flag';

const useTagFilters = ({
    enabledFilters,
}: {
    enabledFilters?: Partial<Record<AvailableTagFilters, boolean>>;
}) => {
    const project = useProject();
    const pirateBrandsQuery = useListTag(
        {
            project_id: project._id,
            options: {
                limit: 0,
                sort: {
                    field: 'name',
                    order: SortOrder.ASC,
                },
                devFilters: {
                    category: 'pirate-brand',
                    model_type: 'target',
                },
            },
        },
        { enabled: !!enabledFilters?.pirate_brand }
    );
    const countriesQuery = useListTag(
        {
            project_id: project._id,
            options: {
                limit: 0,
                sort: {
                    field: 'name',
                    order: SortOrder.ASC,
                },
                devFilters: {
                    category: 'country',
                    model_type: 'authorization',
                },
            },
        },
        { enabled: !!enabledFilters?.country }
    );
    const flagsQuery = useListTag(
        {
            project_id: project._id,
            options: {
                limit: 0,
                sort: {
                    field: 'name',
                    order: SortOrder.ASC,
                },
                devFilters: {
                    category: 'flag',
                    model_type: 'authorization',
                },
            },
        },
        { enabled: !!enabledFilters?.flag }
    );

    return {
        pirateBrandsQuery,
        countriesQuery,
        flagsQuery,
    }
};

export default useTagFilters;