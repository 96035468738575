import { DataTableSortMeta } from 'primereact/datatable';
import api from 'services/ether/api';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import {
    DetailEvidenceEP,
    ListEvidenceEP,
    InsertEvidenceEP,
    DownloadEvidencesFromAuthorizationParams,
    RemoveEvidenceParams,
    DownloadEvidencesFromTargetParams,
} from './types';
import {
    fileDownloadBlobBase,
    fileStreamingDownload,
    getApiBase,
    listBase,
} from 'services/ether/base';
import { ParamFiltersMeta } from 'services/ether/types';
import { checkFileSizeForEndpoint } from 'utils/errorHandler';
import _ from 'lodash';

const handleDataTableFilterMeta = ({
    filters,
    sort,
}: {
    filters?: ParamFiltersMeta;
    sort?: DataTableSortMeta | null;
}) => {
    return parseDataTableFilterMetaForAPI({
        filters,
        sort,
        nameField: 'file.name',
    });
};

export const listEvidences = ({
    project_id,
    options,
    signal,
}: ListEvidenceEP.Params.Many) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: ListEvidenceEP.Filters.Map = {
        _id: ['_id', devFilters._id],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Evidence[]>({
        endpoint: '/list-evidence',
        handleParams: handleDataTableFilterMeta,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

const _detailEvidence = ({
    project_id,
    options,
    signal,
}: DetailEvidenceEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailEvidenceEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        authorization_id: ['authorization_id', devFilters.authorization_id],
        target_id: ['target_id', devFilters.target_id],
        get_evidence_parsed: [
            'get_evidence_parsed',
            devFilters.get_evidence_parsed,
        ],
        deduplicate: ['deduplicate', devFilters.deduplicate],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.Evidence.Detailed[]>({
        endpoint: '/detail-evidence',
        handleParams: handleDataTableFilterMeta,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailManyEvidences = ({
    project_id,
    options,
    signal,
}: DetailEvidenceEP.Params.Many) => {
    return _detailEvidence({
        project_id,
        options,
        signal,
    });
};

export const detailOneEvidence = async ({
    _id,
    project_id,
    signal,
}: DetailEvidenceEP.Params.One) => {
    const { payload } = await _detailEvidence({
        project_id,
        options: {
            devFilters: {
                _id,
                get_evidence_parsed: true,
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const downloadEvidenceFile = (
    file_id: string,
    file_index: number,
    options?: {
        signal?: AbortSignal;
        token?: string;
    }
) => {
    return fileDownloadBlobBase({
        endpoint: '/download-file',
        params: {
            file_id,
            file_index: file_index.toString(),
            model: 'evidence',
            token: options?.token,
        },
        signal: options?.signal,
    });
};

export const getEvidenceFileURL = (
    file_id: string,
    file_index: number,
    options?: {
        signal?: AbortSignal;
        token?: string;
    }
) => {
    return getApiBase<{ url : string }>({
        endpoint: '/download-file',
        params: {
            file_id,
            file_index: file_index.toString(),
            model: 'evidence',
            token: options?.token,
            return_url: true,
        },
        signal: options?.signal,
    });
}

export const insertEvidence = async ({
    files,
    project_id,
    signal,
}: InsertEvidenceEP.Data) => {
    files.forEach((f) => {
        checkFileSizeForEndpoint({
            endpoint: 'insert-evidence',
            size: f.file.size,
        });
    });

    const formData = new FormData();
    const evidence_files: {
        [key: string]: CaseManagerApp.LocalFile.BaseDetails['meta'];
    } = {};
    const custom_identifiers: {
        [key: string]: string;
    } = {};
    formData.append('project_id', project_id);
    files.forEach(({ file, meta, custom_identifier }, index) => {
        const key = `ev${index}`;
        evidence_files[key] = meta;
        custom_identifiers[key] = custom_identifier;
        formData.append(key, file);
    });
    formData.append('evidence_files', JSON.stringify(evidence_files));
    formData.append('custom_identifiers', JSON.stringify(custom_identifiers));

    const result = await api.post<
        Ether.ApiResponse<InsertEvidenceEP.ApiResponse>
    >('/insert-evidence', formData, {
        signal,
    });

    return result.data.payload;
};

export const downloadEvidencesFromAuthorization = (
    params: DownloadEvidencesFromAuthorizationParams
) => {
    return fileStreamingDownload({
        params: {
            authorization_id: params.authorization_id,
        },
        filename:
            new Date().toISOString().split('T')[0] + `_evidences_authorization`,
        endpoint: '/download-evidences-authorization',
        signal: params.signal,
    });
};

export const downloadEvidencesFromTarget = ({
    params,
    options,
}: DownloadEvidencesFromTargetParams) => {
    const apiParams: Record<string, string | undefined> = {
        project_id: params.project_id,
        target_id: params.target_id,
    };
    if (Array.isArray(params.authorization_id)) {
        apiParams['authorization_id|in'] = params.authorization_id.join(',');
    } else {
        apiParams['authorization_id'] = params.authorization_id;
    }
    return fileStreamingDownload({
        params: apiParams,
        filename: new Date().toISOString().split('T')[0] + `_evidences_target`,
        endpoint: '/download-evidences-zip',
        signal: params.signal,
        onProgressUpdate: options?.onProgressUpdate,
    });
};

export const removeEvidence = ({ evidence_id }: RemoveEvidenceParams) => {
    return new Promise<true>((resolve, reject) => {
        api.post<Ether.ApiResponse<boolean>>('/remove-evidence/' + evidence_id)
            .then((res) => {
                const data = res.data;
                const item = data.payload;
                if (!item) reject(new Error('error removing evidence'));
                else resolve(true);
            })
            .catch((err) => {
                if (
                    err instanceof Error &&
                    err.message.includes('evidence not found')
                )
                    resolve(true);
                reject(err);
            });
    });
};

export const removeManyEvidences = async (evidence_ids: string[]) => {
    const promises = evidence_ids.map((evidence_id) =>
        removeEvidence({ evidence_id })
    );
    try {
        await Promise.all(promises);
        return true;
    } catch (err) {
        throw new Error('error removing evidence');
    }
};
