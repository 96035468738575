import {
    Datepicker,
    Dropdown,
    InputNumber,
    MultiSelect,
} from 'components/ethercity-primereact';
import { useLocalization } from 'hooks/context/useLocalization';
import { FilterMatchMode, FilterOperator, LocaleOptions } from 'primereact/api';
import { Button } from 'primereact/button';
import {
    DataTableFilterMeta,
    DataTableFilterMetaData,
    DataTableOperatorFilterMetaData,
} from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { useRef, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { DropdownProps } from 'primereact/dropdown';
import { Chip } from 'primereact/chip';
import { FilterOption } from '../CMDataTable/types';
import clipboard from 'clipboardy';
import { format as formatDate } from 'date-fns';

const DateMatchModeDropdown: React.FC<DropdownProps> = (props) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const options = [
        {
            label: primereact.dateIs,
            value: FilterMatchMode.DATE_IS,
        },
        {
            label: primereact.dateIsNot,
            value: FilterMatchMode.DATE_IS_NOT,
        },
        {
            label: primereact.dateBefore,
            value: FilterMatchMode.DATE_BEFORE,
        },
        {
            label: primereact.dateAfter,
            value: FilterMatchMode.DATE_AFTER,
        },
    ];

    return <Dropdown className='w-full' {...props} options={options} />;
};

const NumberMatchModeDropdown: React.FC<DropdownProps> = (props) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const options = [
        {
            label: primereact.equals,
            value: FilterMatchMode.EQUALS,
        },
        {
            label: primereact.notEquals,
            value: FilterMatchMode.NOT_EQUALS,
        },
        { label: primereact.gt, value: FilterMatchMode.GREATER_THAN },
        {
            label: primereact.gte,
            value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
        { label: primereact.lt, value: FilterMatchMode.LESS_THAN },
        { label: primereact.lte, value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO },
    ];

    return <Dropdown className='w-full' {...props} options={options} />;
};

const StringMatchModeDropdown: React.FC<DropdownProps> = (props) => {
    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const options = [
        {
            label: primereact.startsWith,
            value: FilterMatchMode.STARTS_WITH,
        },
        {
            label: primereact.contains,
            value: FilterMatchMode.CONTAINS,
        },
        {
            label: primereact.notContains,
            value: FilterMatchMode.NOT_CONTAINS,
        },
        {
            label: primereact.endsWith,
            value: FilterMatchMode.ENDS_WITH,
        },
        {
            label: primereact.equals,
            value: FilterMatchMode.EQUALS,
        },
        {
            label: primereact.notEquals,
            value: FilterMatchMode.NOT_EQUALS,
        },
    ];

    return <Dropdown className='w-full' {...props} options={options} />;
};

type FilterComponentProps = FilterOption & {
    filter: DataTableFilterMetaData | DataTableOperatorFilterMetaData;
    onFilter: (params: {
        value: string | Date | null | number | string[];
        matchMode: FilterMatchMode | undefined;
    }) => void;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
    type,
    label,
    placeholder,
    selectOptions,
    loading,
    filter,
    onFilter,
    hideMatchMode,
}) => {
    const currentFilter =
        'constraints' in filter ? filter.constraints[0] : filter;

    const [, { getPrimereactLocale }] = useLocalization();
    const primereact = getPrimereactLocale();

    const defaultNull =
        type === 'string' ? '' : type === 'multiselect' ? [] : null;
    const defaultMatchMode =
        type === 'date'
            ? FilterMatchMode.DATE_IS
            : type === 'number'
            ? FilterMatchMode.EQUALS
            : FilterMatchMode.CONTAINS;

    const [temporary, setTemporary] = useState<{
        value: string | Date | null | number | string[];
        matchMode: FilterMatchMode | undefined;
    }>({
        value: currentFilter?.value ?? defaultNull,
        matchMode:
            (currentFilter?.matchMode as FilterMatchMode) ?? defaultMatchMode,
    });

    const dropdownProps: DropdownProps = {
        value: temporary.matchMode,
        onChange: (e) =>
            setTemporary((old) => ({
                ...old,
                matchMode: e.value,
            })),
    };

    const overlayRef = useRef<OverlayPanel>(null);

    return (
        <>
            <Button
                onClick={(e) => overlayRef.current?.toggle(e)}
                rounded
                label={label}
                icon='pi pi-angle-down'
                iconPos='right'
            />
            <OverlayPanel ref={overlayRef}>
                <div className='flex flex-col gap-2 w-56'>
                    {type === 'date' ? (
                        <>
                            {!hideMatchMode && (
                                <DateMatchModeDropdown {...dropdownProps} />
                            )}
                            <Datepicker
                                wrapperStyle={{
                                    width: '100%',
                                }}
                                style={{
                                    width: '100%',
                                }}
                                value={
                                    temporary.value == null ||
                                    temporary.value === '' ||
                                    typeof temporary.value === 'string'
                                        ? null
                                        : (temporary.value as Date)
                                }
                                onChange={(e) => {
                                    setTemporary((old) => ({
                                        ...old,
                                        value: e,
                                    }));
                                }}
                            />
                        </>
                    ) : type === 'string' ? (
                        <>
                            {!hideMatchMode && (
                                <StringMatchModeDropdown {...dropdownProps} />
                            )}
                            <InputText
                                value={temporary.value as string}
                                placeholder={placeholder}
                                onChange={(e) =>
                                    setTemporary((old) => ({
                                        ...old,
                                        value: e.target.value,
                                    }))
                                }
                            />
                        </>
                    ) : type === 'number' ? (
                        <>
                            {!hideMatchMode && (
                                <NumberMatchModeDropdown {...dropdownProps} />
                            )}
                            <InputNumber
                                value={temporary.value as number | null}
                                placeholder={placeholder}
                                onChange={(e) =>
                                    setTemporary((old) => ({
                                        ...old,
                                        value: e.value,
                                    }))
                                }
                            />
                        </>
                    ) : type === 'multiselect' ? (
                        <MultiSelect
                            value={temporary.value}
                            options={selectOptions}
                            placeholder={placeholder}
                            className='w-full'
                            onChange={(e) =>
                                setTemporary((old) => ({
                                    ...old,
                                    value: e.value,
                                }))
                            }
                            optionLabel='label'
                            optionValue='value'
                            loading={loading}
                        />
                    ) : (
                        <Dropdown
                            value={temporary.value}
                            options={selectOptions}
                            placeholder={placeholder}
                            className='w-full'
                            onChange={(e) =>
                                setTemporary((old) => ({
                                    ...old,
                                    value: e.target.value,
                                }))
                            }
                            loading={loading}
                        />
                    )}
                    <Button
                        label={primereact.apply}
                        disabled={loading}
                        onClick={(e) => {
                            if (
                                temporary.value != null &&
                                temporary.value !== ''
                            ) {
                                onFilter({
                                    matchMode: temporary.matchMode,
                                    value: temporary.value,
                                });
                                setTemporary((old) => ({
                                    ...old,
                                    value: defaultNull,
                                }));
                            }
                            overlayRef.current?.toggle(e);
                        }}
                    />
                </div>
            </OverlayPanel>
        </>
    );
};

const IDFilter: React.FC<{
    filter: DataTableFilterMetaData | DataTableOperatorFilterMetaData;
    onFilter: (params: {
        value: string | null;
        matchMode: FilterMatchMode | undefined;
    }) => void;
    placeholder?: string;
}> = ({ filter, onFilter, placeholder }) => {
    const [localization] = useLocalization();
    const currentFilter =
        'constraints' in filter ? filter.constraints[0] : filter;

    const [, { getPrimereactLocale }] = useLocalization();

    const primereact = getPrimereactLocale();

    const [temporary, setTemporary] = useState<{
        value: string;
        matchMode: FilterMatchMode | undefined;
    }>({
        value: currentFilter?.value ?? '',
        matchMode:
            (currentFilter?.matchMode as FilterMatchMode) ??
            FilterMatchMode.CONTAINS,
    });

    return (
        <div className='flex flex-row gap-2 w-full'>
            <Button
                icon='pi pi-clipboard'
                size='small'
                tooltip={localization.components.common.button.paste}
                onClick={() =>
                    clipboard
                        .read()
                        .then((data) =>
                            setTemporary((old) => ({ ...old, value: data }))
                        )
                }
            />
            <InputText
                value={temporary.value}
                onChange={(e) =>
                    setTemporary((old) => ({
                        ...old,
                        value: e.target.value,
                    }))
                }
                placeholder={placeholder}
                className='w-80'
            />
            <StringMatchModeDropdown
                value={temporary.matchMode}
                onChange={(e) =>
                    setTemporary((old) => ({
                        ...old,
                        matchMode: e.value,
                    }))
                }
                className='w-40'
            />
            <Button
                label={primereact.apply}
                icon='pi pi-search'
                onClick={() => {
                    if (temporary.value !== '') onFilter(temporary);
                }}
            />
            {!!currentFilter?.value && (
                <Button
                    label={primereact.clear}
                    icon='pi pi-times'
                    onClick={() => {
                        setTemporary((old) => ({
                            ...old,
                            value: '',
                        }));
                        onFilter({
                            matchMode: temporary.matchMode,
                            value: null,
                        });
                    }}
                />
            )}
        </div>
    );
};

const FilterBox: React.FC<{
    filters: DataTableFilterMeta;
    setFilters: (filters: DataTableFilterMeta) => void;
    filterOptions: Record<string, FilterOption>;
    hideBorder?: boolean;
}> = ({ filters, setFilters, filterOptions, hideBorder }) => {
    const [localization, { getPrimereactLocale }] = useLocalization();
    const primereactLocale = getPrimereactLocale();

    if (!filters) return null;

    const filterElements = Object.entries(filterOptions)
        .filter(([key]) => key !== '_cm_name_id')
        .map(([key, value]) => {
            const filterItem = filters[key];
            if (!filterItem) return null;

            return (
                <FilterComponent
                    key={key}
                    type={value.type}
                    label={value.label}
                    placeholder={value.placeholder}
                    filter={filterItem}
                    selectOptions={value.selectOptions}
                    onFilter={(f) => {
                        if (setFilters)
                            setFilters({
                                ...filters,
                                [key]: {
                                    constraints: [f],
                                    operator: FilterOperator.AND,
                                },
                            });
                    }}
                    hideMatchMode={value.hideMatchMode}
                    loading={value.loading}
                />
            );
        })
        .filter((f) => !!f);

    const filterResults = Object.entries(filters)
        .filter(([, f]) => {
            const currentFilter = 'constraints' in f ? f.constraints[0] : f;
            return currentFilter?.value != null && currentFilter.value !== '';
        })
        .map(([k, f]) => {
            const currentFilter = 'constraints' in f ? f.constraints[0] : f;
            if (currentFilter == null) return null;
            const options = filterOptions[k];
            const leftSideLabel = options?.label;

            const { value, matchMode } = currentFilter;

            const middleLabel =
                matchMode &&
                matchMode in primereactLocale &&
                options?.type !== 'dropdown' &&
                options?.type !== 'multiselect'
                    ? primereactLocale[matchMode as keyof LocaleOptions]
                    : '';
            const rightSideLabel =
                value instanceof Date
                    ? formatDate(value, 'yyyy-MM-dd')
                    : options?.type === 'dropdown'
                    ? options.selectOptions?.find((d) => d.value === value)
                          ?.label ?? value
                    : options?.type === 'multiselect'
                    ? options.selectOptions
                          ?.filter((d) =>
                              value.find((d2: string) => d.value === d2)
                          )
                          .map((d) => d.label)
                          .join(', ')
                    : value;
            return (
                <Chip
                    key={k}
                    label={
                        k === '_cm_name_id'
                            ? value
                            : !!options?.hideMatchMode
                            ? `${leftSideLabel}: ${value}`
                            : `${leftSideLabel}: ${middleLabel} ${rightSideLabel}`
                    }
                    removable
                    onRemove={() => {
                        const currentFilter =
                            'constraints' in f ? f.constraints[0] : f;
                        if (!currentFilter || !setFilters) return;
                        setFilters({
                            ...filters,
                            [k]: {
                                constraints: [
                                    {
                                        matchMode: currentFilter.matchMode,
                                        value: null,
                                    },
                                ],
                                operator: FilterOperator.AND,
                            },
                        });
                    }}
                />
            );
        });

    const idFilter = filters['_cm_name_id'];
    const idFilterOptions = filterOptions['_cm_name_id'];

    const wrapperClassNames = ['p-4', 'rounded-md', 'my-2'];
    if (!hideBorder)
        wrapperClassNames.push(
            'border',
            'border-solid',
            'border-gray-blue-900'
        );

    return (
        <div className={wrapperClassNames.join(' ')}>
            {idFilter && (
                <IDFilter
                    filter={idFilter}
                    placeholder={idFilterOptions?.placeholder}
                    onFilter={(e) => {
                        if (!filters || !setFilters) return;
                        setFilters({
                            ...filters,
                            _cm_name_id: {
                                constraints: [e],
                                operator: FilterOperator.AND,
                            },
                        });
                    }}
                />
            )}
            {filterElements.length > 0 && (
                <h3>{localization.components.common.datatable.filters}</h3>
            )}
            <div className='flex flex-row gap-1'>{filterElements}</div>
            {filterResults.length > 0 && (
                <h3>
                    {localization.components.common.datatable.appliedFilters}
                </h3>
            )}
            <div className='flex flex-row gap-1'>{filterResults}</div>
        </div>
    );
};

export default FilterBox;
