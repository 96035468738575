import { UseMutationResult, useMutation } from '@tanstack/react-query';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import { upsertBlockOrder } from 'services/ether/case-manager/block-orders';
import { UpsertBlockOrderEP } from 'services/ether/case-manager/block-orders/types';
import { getErrorToast } from 'utils/errorHandler';

const useUpsertBlockOrder = ({
    onInsert,
}: {
    onInsert?: () => void;
}): {
    insertBlockOrder: (data: UpsertBlockOrderEP.InsertData) => void;
    updateBlockOrder: (data: UpsertBlockOrderEP.UpdateData) => void;
    status: UseMutationResult['status'];
} => {
    const [localization] = useLocalization();
    const toast = useToast();

    const { hardRefresh, softRefresh } = useQueryRefresh();

    const handleUpdate = ({
        data,
        params,
    }: {
        data: UpsertBlockOrderEP.Result;
        params: UpsertBlockOrderEP.UpsertData;
    }) => {
        hardRefresh(['block-order']);
        softRefresh([
            'authorization',
            'target',
            'unblock-order',
            'metrics',
            'me',
        ]);
        const isEdited = '_id' in params;
        const locale = localization.endpoints.blockOrder.upsert_block_order;

        const targetsDict: Record<string, number> = {};
        let ipTargets = 0;
        let domainTargets = 0;
        data.targets.forEach(({ value, type }) => {
            const val = targetsDict[value] ?? 0;
            targetsDict[value] = val + 1;
            if (val === 0) {
                if (type === 'domain') domainTargets += 1;
                if (type === 'ipv4' || type === 'ipv6') ipTargets += 1;
            }
        });

        const totalTargets = Object.values(targetsDict).length;
        const duplicatedTargets = Object.values(targetsDict).reduce(
            (previous, current) => previous + (current - 1),
            0
        );

        toast.show({
            summary: isEdited ? locale.updateSuccess : locale.createSuccess,
            detail: (
                <div className='flex flex-col gap-2'>
                    <span>
                        {locale.upsertSuccessDetails
                            .replace('{count}', totalTargets.toString())
                            .replace('{name}', params.name ?? '-')}
                    </span>
                    <span>
                        {locale.upsertSuccessDetailsDuplicated.replace(
                            '{count}',
                            duplicatedTargets.toString()
                        )}
                    </span>
                    <span>
                        {locale.upsertSuccessDetailsDomains.replace(
                            '{count}',
                            domainTargets.toString()
                        )}
                    </span>
                    <span>
                        {locale.upsertSuccessDetailsIPs.replace(
                            '{count}',
                            ipTargets.toString()
                        )}
                    </span>
                </div>
            ),
            severity: 'success',
            life: 30000,
        });
        if (onInsert) onInsert();
    };

    const upsertBlockOrderMutation = useMutation<
        UpsertBlockOrderEP.Result,
        Error,
        UpsertBlockOrderEP.UpsertData
    >({
        mutationFn: (data) => upsertBlockOrder(data),
        onSuccess: (data, params) => handleUpdate({ data, params }),
        onError: (err) => toast.show(getErrorToast(err.message, localization)),
    });

    return {
        insertBlockOrder: (data) => upsertBlockOrderMutation.mutate(data),
        updateBlockOrder: (data) => upsertBlockOrderMutation.mutate(data),
        status: upsertBlockOrderMutation.status,
    };
};

export default useUpsertBlockOrder;
