import useListAuthorizationConfig from 'hooks/queries/authorization-config/useListAuthorizationConfig';
import { useLocalization } from 'hooks/context/useLocalization';
import { useProject } from 'hooks/context/project/useProject';
import SelectManyItems from 'components/form/SelectItems/SelectManyItems';
import { SelectManyItemsProps } from 'components/form/SelectItems/types';
import { ListAuthorizationConfigEP } from 'services/ether/case-manager/authorization-config/types';

const SelectManyAuthorizationConfigs: React.FC<
    SelectManyItemsProps<
        Ether.CaseManager.AuthorizationConfig,
        ListAuthorizationConfigEP.Filters.Options
    >
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();
    const query = useListAuthorizationConfig({
        project_id: project._id,
        options: { ...props.queryOptions, limit: 0 },
    });

    return (
        <SelectManyItems
            title={localization.models.authorizationConfig.plural}
            placeholder={
                localization.components.models.authorizationConfig.form
                    .selectManyPlaceholder
            }
            label={localization.models.authorizationConfig.plural}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectManyAuthorizationConfigs;
