import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyTags } from 'services/ether/case-manager/tags';
import { DetailTagEP } from 'services/ether/case-manager/tags/types';

const useDetailTag = (
    params: DetailTagEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['tag', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) => detailManyTags({ ...params, signal }),
        ...queryOptions,
    });

    return query;
};

export default useDetailTag;
