import { useProject } from 'hooks/context/project/useProject';
import useDetailBlockOrder from 'hooks/queries/block-order/useDetailBlockOrder';
import { useLocalization } from 'hooks/context/useLocalization';
import SelectManyItems from 'components/form/SelectItems/SelectManyItems';
import { SelectManyItemsProps } from 'components/form/SelectItems/types';
import { ListBlockOrderEP } from 'services/ether/case-manager/block-orders/types';

const SelectManyBlockOrders: React.FC<
    SelectManyItemsProps<Ether.CaseManager.BlockOrder, ListBlockOrderEP.Filters.Options>
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();
    const query = useDetailBlockOrder(
        {
            project_id: project._id,
            options: {
                ...props.queryOptions,
                limit: 0,
            },
        },
        'list'
    );

    return (
        <SelectManyItems
            title={localization.models.blockOrder.plural}
            placeholder={
                localization.components.models.blockOrder.form.selectManyPlaceholder
            }
            label={localization.models.blockOrder.plural}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectManyBlockOrders;
