import { Button, ButtonProps } from 'primereact/button';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { useMemo, useRef } from 'react';

export type MenuItemWithLoading = MenuItem & {
    items?: MenuItemWithLoading[];
    loading?: boolean;
};

const isChildrenLoading = (items: MenuItemWithLoading[]) => {
    const isLoading = items.find((item) => {
        if (item.items) {
            const isLoading = isChildrenLoading(item.items);
            if (isLoading) return true;
        };
        return !!item.loading;
    });
    return !!isLoading;
};

const ButtonMenu: React.FC<{
    items: MenuItemWithLoading[];
    buttonProps: Omit<ButtonProps, 'onClick'>;
}> = ({ items, buttonProps }) => {
    const menuRef = useRef<Menu>(null);

    const isLoading = useMemo(() => isChildrenLoading(items), [items]);

    if (items.length <= 0) return null;

    return (
        <>
            <Menu model={items} ref={menuRef} popup />
            <Button
                {...buttonProps}
                loading={buttonProps.loading || isLoading}
                onClick={(event) => menuRef.current?.toggle(event)}
            />
        </>
    );
};

export default ButtonMenu;
