import InsertOfficialDocumentSimple from 'components/models/Document/InsertOfficialDocumentSimple';
import { InsertOfficialDocumentSimpleRequiredProps } from 'components/models/Document/InsertOfficialDocumentSimple/types';
import { useCallback, useMemo, useState } from 'react';

const useInsertDocumentSimpleDialog = () => {
    const [visible, setVisible] = useState(false);
    const [props, setProps] =
        useState<InsertOfficialDocumentSimpleRequiredProps | null>(null);

    const show = (props: InsertOfficialDocumentSimpleRequiredProps) => {
        setProps(props);
        setVisible(true);
    };
    const hide = useCallback(() => {
        setVisible(false);
        setProps(null);
    }, []);

    const dialog = useMemo(
        () =>
            visible &&
            props && (
                <InsertOfficialDocumentSimple
                    visible={true}
                    onHide={hide}
                    {...props}
                />
            ),
        [hide, props, visible]
    );

    return {
        dialog,
        show,
        hide,
    };
};

export default useInsertDocumentSimpleDialog;
